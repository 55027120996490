import React, { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Input from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

// import PhoneInput from 'react-phone-number-input'

function ContactForm(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const completeData = props.completeData;
  console.log("completeData", completeData) 
  const [formValues, setFormValues] = useState({
    fname: "",
    lname: "",
    email: "",
    number: "",
  });
  const [errors, setErrors] = useState({
    fname: "",
    lname: "",
    email: "",
    number: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    // console.log("formValues", formValues);
    let valid = true;
    const newErrors = { fname: "", lname: "", email: "", number: "" };

    if (formValues.fname.trim() === "") {
      newErrors.fname = "First Name is required";
      valid = false;
    }
    if (formValues.lname.trim() === "") {
      newErrors.lname = "Last Name is required";
      valid = false;
    }
    if (formValues.email.trim() === "") {
      newErrors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email is invalid";
      valid = false;
    }
    if (formValues.number.trim() === "") {
      newErrors.number = "Phone number is required";
      valid = false;
    } else if(formValues.number.length<12){
      newErrors.number = "Correct Phone number is required";
      valid = false;
    }
    else if(formValues.number.length>12) { 
      newErrors.number = "Correct Phone number is required";
      valid = false;
    }
  
    const updatedFormValues = {
      fname: formValues.fname,
      lname: formValues.lname,
      email: formValues.email,
      number: formValues.number,
      dateStr: completeData.dateStr,
      campaign: completeData.campaign,  
      dayName: completeData.dayName,
      startTime: completeData.selectedOption,
      endTime: completeData.endTime,
      formattedDate: completeData.formattedDate,
      hotel: completeData.hotel,
      street: completeData.street,
      lobyName: completeData.lobyName,
      city: completeData.city,
      zipcode: completeData.zipcode,
      state: completeData.state,
      hotelPhone:completeData.hotelPhone,
      timeOfSet: completeData.timeOfSet,
      locationName: completeData.locationName,

    };
  //  console.log("updatedFormValues", updatedFormValues)

    if (valid) {
      const response = await fetch(
        "https://services.leadconnectorhq.com/hooks/kJOiQyFsJQQj5rYEbrCO/webhook-trigger/714637bf-f493-46c8-acd3-4b6b406e6704",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedFormValues),
        }
      );

      if (response.ok) {
        Cookies.set("UserData", JSON.stringify(updatedFormValues));
        Cookies.set("refresh", JSON.stringify(12));
        // Code to handle successful form submission goes here
        // console.log("Form submitted successfully!");
        setIsLoading(false); 
        navigate("/thanks");
      } else {
        // Code to handle form submission error goes here
        // console.error("Error submitting form");
      }
    } else {
      setErrors(newErrors);
      setIsLoading(false);

    }
    
  };

  return (
    <form onSubmit={handleSubmit} className="modal-form">
      <label>
        <input
          type="text"
          placeholder="First Name"
          name="fname"
          value={formValues.fname}
          onChange={(event) =>
            setFormValues({ ...formValues, fname: event.target.value })
          }
        />
        {errors.fname && <div className="error">{errors.fname}</div>}
      </label>
      {/* <br /> */}
      <label>
        <input
          type="text"
          placeholder="Last Name"
          name="lname"
          value={formValues.lname}
          onChange={(event) =>
            setFormValues({ ...formValues, lname: event.target.value })
          }
        />
        {errors.lname && <div className="error">{errors.lname}</div>}
      </label>
      {/* <br /> */}
      <label>
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={formValues.email}
          onChange={(event) =>
            setFormValues({ ...formValues, email: event.target.value })
          }
        />
        {errors.email && <div className="error">{errors.email}</div>}
      </label>
      {/* <br /> */}

      <label>
        <Input
          country="US"
          international
          withCountryCallingCode
          name="number"
          placeholder="Number"
          onChange={(value) => setFormValues({ ...formValues, number: value })}
        />

        {errors.number && <div className="error">{errors.number}</div>}
      </label>
      {/* <br /> */}
      <div className="submit-form">
        <p>
        By providing my mobile phone & email, I give consent to RealSuccess for reminder calls, emails, texts, for any updated event information as applicable using automated technology for my upcoming events. Consent is not a requirement to attend.
        </p>


        <button type="submit"  className="submit_btn_form" disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Reserve Your Seat'}
      </button>

        {/* <input type="submit" value="Reserve Your Seat" disabled={isLoading}/> */}
      </div>
    </form>
  );
}

export default ContactForm;
