import React, { useState, useEffect} from "react";

import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import cross from "../src/images/cross.png";
import ContactForm from "./ContactForm";
 
const Calendar = (props) => {
  
  const itemdata = props.alldata;
  const dateStr = itemdata.date; 
  const locationName= props.cityName.key;
  // Use Moment.js to parse the date string and format it
  const dateObj = moment(dateStr, "MMMM Do YYYY");
  const dayName = dateObj.format("dddd"); // e.g. "Monday"
  const dateName = dateObj.format("DD"); // e.g. "Monday"
  const monthName = dateObj.format("MMMM"); // e.g. "February"

  const dayFormat = dayName.slice(0, 3); // returns "Mar"
  const monthFormat = monthName.slice(0, 3); // returns "Mar"

  const formattedDate = moment(dateStr, "MMMM D YYYY").format("YYYY-MM-DD");
 
  const [campaign, setCampaign] = useState();
  const [newEmail, setnewEmail] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  // console.log("selectedOption a", selectedOption);
  const [selectedOptionCalnder, setSelectedOptionCalendar] = useState();
  const [endTime, setEndTime] = useState();

  // console.log("setEndTime", endTime);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedOptionCalendar(moment(event.target.value, 'h:mm A').format('h:mm A'));
    console.log("event.target.value", event.target.value)
    const newTime = moment(event.target.value, "h:mm A")
      .add(2, "hours")
      .format("h:mm A");
      // console.log("setEndTime inner", newTime);
    setEndTime(newTime);
   };

  useEffect(() => {
    setSelectedOption(
      itemdata.time.find((option) => {
        return !!option; // Ensure that the option is truthy (not null or undefined)
      })
    );
 
  }, [itemdata.time]);

  useEffect(() => {
    if (selectedOption) {
      const newEndTime = moment(selectedOption, 'h:mm A').add(2, 'hours').format('h:mm A');
      setEndTime(newEndTime);
  
    }
  }, [selectedOption]);

  const registerData = (e) => { 
    e.preventDefault();
    setnewEmail(true);
     setEndTime(moment(selectedOption, "h:mm A").add(2, "hours").format("h:mm A"));
  };

  const dynmicData = {
    dateName,
    monthName,
    dateStr,
    dayName,
    selectedOption,
    endTime,
    formattedDate,
    campaign,
    timeOfSet: itemdata.timeOfSet,
    hotel: itemdata.hotel,
    street: itemdata.street,
    lobyName: itemdata.lobyName,
    city: itemdata.city,
    zipcode: itemdata.zipcode,
    state: itemdata.state,
    hotelPhone: itemdata.hotelPhone,
    locationName,
  };

  const onClose = () => {
    setnewEmail(false);
  };

  const radioButtons = itemdata.time.map((option, index) => {
    if (option) {
      return (
        <label key={index}>
          <input
            type="radio"
            value={option}
            checked={selectedOption === option}
            onChange={handleOptionChange}
          />
          {option}
        </label>
      );
    }
    else {
      return null; // or any appropriate element
    }
  });



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const campaign = urlParams.get("campaign");
    setCampaign(campaign);
  }, []);

  return (
    <div className="location-check-data">
      <div className="  justify-content-between calendar-box-main">
        <div>
          <div className="caledar-box">
            <span>{monthFormat}</span>
            <p>
              <strong>{dateName}</strong>
              {dayFormat}
            </p>
          </div>
        </div>
        <div className="  p-0 ps-1 calendar-title">
          <h5>{itemdata.hotel}</h5>
          <p>
            {itemdata.street}, {itemdata.city}, {itemdata.state} {itemdata.zipcode}
          </p>
        </div>
        <div className=" p-0 text-center">
          <button onClick={registerData}>Register</button>
        </div>
      </div>
      <div className="swal_al">
        <SweetAlert
          custom
          title="My Title"
          show={newEmail}
          onConfirm={registerData}
          onCancel={() => setnewEmail(false)}
          confirmBtnCssClass="confirm-btn-clss"
          showConfirmButton="false"
          customClass="sweet_alert"
        >
          <div className="swt_alert">
            <div className="heading-modal">
              <h4>Reserve Your Seat </h4>
              <img src={cross} alt="cross" onClick={onClose} />
            </div>

            <div className="modal-timing">
              <h3>
                Pick a Time <sup>*</sup>
              </h3>
              {radioButtons}
            </div>

            <ContactForm completeData={dynmicData} />

             
          </div>
        </SweetAlert>
        {/* Popup close */}
      </div>
    </div>
  );
};

export default Calendar;
