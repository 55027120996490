import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import Header from "./Header";
import Calendar from "./Calendar";
import loading from "../src/images/loading.gif";
import Footer from "./Footer";
import ChooseUs from "./ChooseUs";
import About from "./About";
import { Helmet } from "react-helmet";

function Home() {
  const [sheetData, setSheetData] = useState(null);
  const [innerData, setInnerData] = useState();
  const [activeKey, setActiveKey] = useState();
  const [firstChild, setFirstChild] = useState();
  const [selectChild, setSelectChild] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const location = urlParams.get("loc");
    setSelectChild(location === null ? 0 : location);
    // setSelectChild(location);
    // console.log("location", location)
    // console.log("selectChild", selectChild)
  }, [selectChild]);



  useEffect(() => {
    axios
      .get("https://lp.taxliensuccess.com/sheetData")
      .then((response) => {
        let a = [];
        for (const [key, value] of Object.entries(response.data)) {
          a.push({
            key: key,
            value: value,
          });
        }
        setSheetData(a);

        if (selectChild >= a.length) {
          setActiveKey(a[0].key);
          setFirstChild(a[0].key);
          setInnerData(a[0]);
        } else {
          setActiveKey(a[selectChild].key);
          setFirstChild(a[selectChild].key);
          setInnerData(a[selectChild]);
        }


        // setActiveKey(a[selectChild].key);
        // setFirstChild(a[selectChild].key);
        // setInnerData(a[selectChild]);
        // console.log("setFirstChild", a[selectChild].key)
        // console.log("setInnerData", a[selectChild])
      })
      .catch((error) => console.error(error));
  }, [selectChild]);
  useEffect(() => {
    if (!sheetData) {
      const timeout = setTimeout(() => {

        window.location.reload(); // Refresh the page
      }, 10000);

      return () => {
        clearTimeout(timeout); // Clear the timeout when the component unmounts
      };
    }
  }, [sheetData]);
  const handleCheck = (item) => {
    setInnerData((prevInnerData) => {
      return { ...prevInnerData, ...item };
    });


    setFirstChild(item.key);
    setActiveKey(item.key);
    const section = document.getElementById("mySection");
    section.scrollIntoView({ behavior: "smooth" });
  };
  const handleCheckboxChange = (item) => {
    // console.log("item 2", item);
  };

{/* // Installation script generated by Ad Analytics */ }
  const adScript = `
(function(w, d){
var id='spdt-capture', n='script';
if (!d.getElementById(id)) {
w.spdt =
w.spdt ||
function() {
(w.spdt.q = w.spdt.q || []).push(arguments);
};
var e = d.createElement(n); e.id = id; e.async=1;
e.src = 'https://pixel.byspotify.com/ping.min.js';
var s = d.getElementsByTagName(n)[0];
s.parentNode.insertBefore(e, s);
}
w.spdt('conf', { key: 'a9d12a39cf7e41c0b0273e19eef95df8' });
w.spdt('view');
})(window, document);
`;

  return (
    <div>
      <Helmet>
        {/* <!-- Facebook Pixel Code --> */}

        <script>{`
       !function(f,b,e,v,n,t,s)
       {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
       n.callMethod.apply(n,arguments):n.queue.push(arguments)};
       if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
       n.queue=[];t=b.createElement(e);t.async=!0;
       t.src=v;s=b.getElementsByTagName(e)[0];
       s.parentNode.insertBefore(t,s)}(window, document,'script',
       'https://connect.facebook.net/en_US/fbevents.js');
       fbq('init', '319878429693238');
       fbq('track', 'PageView');       
        `}</script>
        {/* <!-- clarity Code --> */}

        <script>{`
       (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "h60ub7uev3");       
        `}</script>

        {/* <!-- nudgify Code --> */}

        <script>{`
         (function(w){
          var k="nudgify",n=w[k]||(w[k]={});
          n.uuid="188c3d46-5f4e-4a8d-9806-e440faedad89";
          var d=document,s=d.createElement("script");
          s.src="https://pixel.nudgify.com/pixel.js";
          s.async=1;
          s.charset="utf-8";
          d.getElementsByTagName("head")[0].appendChild(s)
      })(window)   
        `}</script>
        {/* <!-- Tik Tok Code --> */}

        <script>{`
         !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        
          ttq.load('CFUH7KBC77U840VNCFO0');
          ttq.page();
        }(window, document, 'ttq'); 
        `}</script>
        {/* <!-- Google tag (gtag.js) --> */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-630361343"></script>
        <script>
          {` window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-630361343');`}
        </script>


        {/* // Installation script generated by Ad Analytics */}


        <script>{adScript}</script>

      </Helmet>

      <div className="confirm-btn-clss">
        <img src="https://strategicmedia-23-adswizz.attribution.adswizz.com/fire?pixelId=ad9ff7b3-e6f3-4563-92af-299a17659f3c&type=sitevisit&subtype=PageVisit1&aw_0_req.gdpr=true&redirectURL=aHR0cHM6Ly9waXhlbC50YXBhZC5jb20vaWRzeW5jL2V4L3JlY2VpdmU_cGFydG5lcl9pZD0yOTk0JjwjaWYgcmVxdWVzdC5saXN0ZW5lcklkP21hdGNoZXMoJ1swLTlhLWZdezh9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezEyfScpPnBhcnRuZXJfdHlwZWRfZGlkPSU3QiUyMkhBUkRXQVJFX0FORFJPSURfQURfSUQlMjIlM0ElMjIke3JlcXVlc3QubGlzdGVuZXJJZH0lMjIlN0Q8I2Vsc2VpZiByZXF1ZXN0Lmxpc3RlbmVySWQ_bWF0Y2hlcygnWzAtOUEtRl17OH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17MTJ9Jyk-cGFydG5lcl90eXBlZF9kaWQ9JTdCJTIySEFSRFdBUkVfSURGQSUyMiUzQSUyMiR7cmVxdWVzdC5saXN0ZW5lcklkfSUyMiU3RDwjZWxzZT5wYXJ0bmVyX2RldmljZV9pZD0ke3JlcXVlc3QubGlzdGVuZXJJZCF9PC8jaWY-" />

      </div>

      <div>
        <Header />
      </div>

      {sheetData?.length > 0 ? (
        <section className="section-one">
          <div className="container ">
            <div className="top-heading">
              <h1>
                Register for the free live <br />
                2-Hour Class
              </h1>
              <p>Take control of your Financial Goals</p>
            </div>
            <div className="locations-box">
              <div className="loaction-box card">
                <div className="location-check">
                  <h2>
                    <span>1</span>Choose Your location
                  </h2>
                  <Form>
                    <div>
                      <div className="row">
                        {sheetData.length > 0 ? (
                          sheetData.map((item, index) => (
                            <div key={index}>
                              <div className="col-md-12">
                                <Form.Check
                                  onClick={() => handleCheck(item)}
                                  className={
                                    activeKey === item.key ? "active" : ""
                                  }
                                  inline
                                  label={item.key}
                                  name="group1"
                                  type="radio"
                                  checked={item.key === firstChild}
                                  onChange={handleCheckboxChange}
                                  id={index + 1}
                                />
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>Loading...</p>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              {innerData ? (
                <div className="loaction-box  card" id="mySection">
                  <div className=" location-inner ">
                    <h2>
                      <span>2</span>Choose your Class
                    </h2>
                    {innerData?.value?.map((inneritem, index) => (
                      <Calendar
                        key={index}
                        cityName={innerData}
                        alldata={inneritem}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ) : (
        <div className="loading">
          <img src={loading} alt="loading" />
        </div>
      )}
      <div className="choose-us">
        <ChooseUs />
      </div>
      <About />
      <Footer />
    </div>
  );
}

export default Home;
